import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Navbar from "scalpel-components/Navbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "scalpel-components/Users/NewUser/components/UserInfo";
import Address from "scalpel-components/Users/NewUser/components/AdditionalInfo";
import Socials from "scalpel-components/Users/NewUser/components/Socials";
import Profile from "scalpel-components/Users/NewUser/components/Profile";
import ProfilePic from "scalpel-components/Users/NewUser/components/ProfilePic";

// NewUser layout schemas for form and form feilds
import validations from "scalpel-components/Users/NewUser/schemas/validations";
import form from "scalpel-components/Users/NewUser/schemas/form";
import initialValues from "scalpel-components/Users/NewUser/schemas/initialValues";

import axiosInstance from "context/AxiosInstance";

function getSteps(): string[] {
  return ["User Info", "Address", "Profile Picture"];
}

// Reducer for every step
function getStepContent(stepIndex: number, formData: any): JSX.Element {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <Address formData={formData} />;
    case 2:
      return <ProfilePic formData={formData} />;
    // case 3:
    //   return <Profile formData={formData} />;
    default:
      return null;
  }
}

// NEED
// User properties
// First name, last name, email, position, password, repeat password, region code/name, languages, roles, profile picture, team leader, team members?

function NewUser(): JSX.Element {
  // Track steps
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  // Initialize formik
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values: any, actions: any) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    // const result = await axiosInstance.post("/users", values);

    const test = {
      firstName: "Ryan",
      lastName: "Kennedy",
      position: { name: "Manager", label: "Manager" },
      email: "rykennedy@merkleinc.com",
      password: "cE@W0y7U",
      repeatPassword: "cE@W0y7U",
      address1: "",
      address2: "",
      region: {
        name: "Antarctica",
        dial_code: "+672",
        emoji: "🇦🇶",
        code: "AQ",
      },
      languages: ["french", "bengali"],
      roles: ["admin", "newsletter"],
      teamLeader: "d602d07f-544c-4a43-9c55-5e05c0fd3370",
      profilePic: "54f8ce33-1e33-4b60-a192-3847bfd7f61f",
      city: "",
      zip: "",
      twitter: "",
      facebook: "",
      instagram: "",
      publicEmail: "",
      bio: "",
    };

    console.log(JSON.stringify(values));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <Navbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New User
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                Follow the steps below to add a new user.
              </MDTypography>
            </MDBox>

            <Formik
              initialValues={initialValues}
              // validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                isSubmitting,
              }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          setFieldValue,
                          handleBlur,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "create" : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
