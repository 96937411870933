const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    position: {
      name: "position",
      label: "Position",
      type: "select",
      errorMsg: "Position is required.",
      invalidMsg: "Position is not valid.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
    region: {
      name: "region",
      label: "Region",
      type: "select",
      errorMsg: "Region is required.",
      invalidMsg: "Your region is invalid.",
    },
    languages: {
      name: "languages",
      label: "Languages",
      type: "select",
      errorMsg: "Language is required.",
      invalidMsg: "Your language is invalid.",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "select",
      errorMsg: "Roles are required.",
      invalidMsg: "Your roles are invalid.",
    },
    teamLeader: {
      name: "teamLeader",
      label: "Team Leader",
      type: "select",
      errorMsg: "Team leader is required.",
      invalidMsg: "The team leader is invalid.",
    },
    profilePic: {
      name: "profilePic",
      label: "Profile Picture",
      type: "image",
      errorMsg: "Profile picture is required.",
      invalidMsg: "Your profile picture is invalid.",
    },
    address1: {
      name: "address1",
      label: "Address 1",
      type: "text",
      errorMsg: "Address is required.",
    },
    address2: {
      name: "address2",
      label: "Address 2",
      type: "text",
    },
    city: {
      name: "city",
      label: "City",
      type: "text",
      errorMsg: "City is required.",
    },
    zip: {
      name: "zip",
      label: "Zip",
      type: "number",
      errorMsg: "Zip is required.",
      invalidMsg: "Zipcode is not valie (e.g. 70000).",
    },
    twitter: {
      name: "twitter",
      label: "Twitter Handle",
      type: "text",
      errorMsg: "Twitter profile is required.",
    },
    facebook: {
      name: "facebook",
      label: "Facebook Account",
      type: "text",
    },
    instagram: {
      name: "instagram",
      label: "Instagram Account",
      type: "text",
    },
    publicEmail: {
      name: "publicEmail",
      label: "Public Email",
      type: "email",
    },
    bio: {
      name: "bio",
      label: "Bio",
    },
  },
};

export default form;
