/* eslint-disable react/prop-types */
// Argon Dashboard 2 PRO MUI components
import MDBadge from "components/MDBadge";

// ProductsList page components
import ProductCell from "scalpel-components/Users/Overview/components/ProductCell";

// Badges
const outOfStock = (
  <MDBadge
    variant="contained"
    color="error"
    size="xs"
    badgeContent="out of stock"
    container
  />
);
const inStock = (
  <MDBadge
    variant="contained"
    color="success"
    size="xs"
    badgeContent="in stock"
    container
  />
);

// Data tables
// Columns are headers.
// user email, full name, position,
const columnsData = [
  { Header: "firstName", accessor: "firstName" },
  { Header: "email", accessor: "email" },
  { Header: "position", accessor: "position" },
  { Header: "action", accessor: "action" },
];

export default columnsData;
