import checkout from "scalpel-components/Users/NewUser/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    email,
    password,
    repeatPassword,
    address1,
    address2,
    region,
    languages,
    roles,
    teamLeader,
    profilePic,
    city,
    zip,
    position,
    twitter,
    facebook,
    instagram,
    publicEmail,
    bio,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [position.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [region.name]: "",
  [languages.name]: "",
  [roles.name]: "",
  [teamLeader.name]: "",
  [profilePic.name]: "",
  [city.name]: "",
  [zip.name]: "",
  [twitter.name]: "",
  [facebook.name]: "",
  [instagram.name]: "",
  [publicEmail.name]: "",
  [bio.name]: "",
};

export default initialValues;
