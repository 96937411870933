// types
type Types = any;

const caloriesChartData: Types = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Calories",
      color: "dark",
      data: [50, 45, 60, 60, 80, 65, 90, 80, 100],
    },
  ],
};

export default caloriesChartData;
