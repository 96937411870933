import { useEffect, useRef } from "react";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";

// Material Dashboard 2 PRO React context
import { useScaleplUIController } from "context/ScalpelUI";

// Declaring props types for MDDropzone
interface Props {
  options: {
    [key: string | number]: any;
  };
}

function MDDropzone({ options, handleProfilePicChange }: any): JSX.Element {
  const [controller] = useScaleplUIController();
  const { darkMode } = controller;

  const dropzoneRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const uploadDropzone = new Dropzone(dropzoneRef.current, { ...options });
      uploadDropzone.on("addedfile", (file) => {
        // console.log(file);
      });
      uploadDropzone.on("success", (file: any, response: any, xhr: any) => {
        handleProfilePicChange(response);
      });
      return uploadDropzone;
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz: any) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, [options]);

  return (
    <MDDropzoneRoot
      // action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
      ownerState={{ darkMode }}
    >
      <MDBox className="fallback" bgColor="transparent">
        <input name="file" type="file" multiple />
      </MDBox>
    </MDDropzoneRoot>
  );
}

export default MDDropzone;
