/**
 * The base colors for the Material Dashboard 2 PRO React TSUI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Material Dashboard 2 PRO React TSUI Dashboard PRO Material using thie file.
 */

// types
interface ColorsTypes {
  main: string;
  focus: string;
}

interface GradientsTypes {
  main: string;
  state: string;
}

interface SocialMediaColorsTypes {
  main: string;
  dark: string;
}

interface AlertColorsTypes {
  background: string;
  text: string;
}

interface BadgeColorsTypes {
  background: string;
  text: string;
}

interface Types {
  background:
    | {
        default: string;
        sidenav?: string;
        card?: string;
      }
    | any;
  white:
    | {
        main: string;
        focus: string;
      }
    | any;
  text:
    | {
        main: string;
        focus: string;
        primary?: string;
        secondary?: string;
        disabled?: string;
      }
    | any;
  transparent:
    | {
        main: string;
      }
    | any;
  black:
    | {
        light: string;
        main: string;
        focus: string;
      }
    | any;
  primary: ColorsTypes | any;
  secondary: ColorsTypes | any;
  info: ColorsTypes | any;
  success: ColorsTypes | any;
  warning: ColorsTypes | any;
  error: ColorsTypes | any;
  light: ColorsTypes | any;
  dark: ColorsTypes | any;
  grey:
    | {
        [key: string | number]: string;
      }
    | any;
  gradients:
    | {
        primary: GradientsTypes;
        secondary: GradientsTypes;
        info: GradientsTypes;
        success: GradientsTypes;
        warning: GradientsTypes;
        error: GradientsTypes;
        light: GradientsTypes;
        dark: GradientsTypes;
      }
    | any;
  socialMediaColors:
    | {
        facebook: SocialMediaColorsTypes;
        twitter: SocialMediaColorsTypes;
        instagram: SocialMediaColorsTypes;
        linkedin: SocialMediaColorsTypes;
        pinterest: SocialMediaColorsTypes;
        youtube: SocialMediaColorsTypes;
        vimeo: SocialMediaColorsTypes;
        slack: SocialMediaColorsTypes;
        dribbble: SocialMediaColorsTypes;
        github: SocialMediaColorsTypes;
        reddit: SocialMediaColorsTypes;
        tumblr: SocialMediaColorsTypes;
      }
    | any;
  alertColors:
    | {
        primary: AlertColorsTypes;
        secondary: AlertColorsTypes;
        info: AlertColorsTypes;
        success: AlertColorsTypes;
        warning: AlertColorsTypes;
        error: AlertColorsTypes;
        light: AlertColorsTypes;
        dark: AlertColorsTypes;
      }
    | any;
  badgeColors:
    | {
        primary: BadgeColorsTypes;
        secondary: BadgeColorsTypes;
        info: BadgeColorsTypes;
        success: BadgeColorsTypes;
        warning: BadgeColorsTypes;
        error: BadgeColorsTypes;
        light: BadgeColorsTypes;
        dark: BadgeColorsTypes;
      }
    | any;
  coloredShadows:
    | {
        [key: string]: string;
      }
    | any;
  inputBorderColor: string;
  tabs:
    | {
        indicator:
          | {
              boxShadow: string;
            }
          | any;
      }
    | any;
  inputColors: {
    borderColor: { main: string; focus: string };
    error: string;
    success: string;
  };
  sliderColors: {
    thumb: { borderColor: string };
  };
  circleSliderColors: {
    background: string;
  };
}

const colors: Types = {
  // background: {
  //   default: "#f0f2f5",
  // },
  background: {
    default: "#f8f9fa",
    dark: "#172b4d",
    // sidenav: "#111c44",
    // card: "#111c44",
  },

  text: {
    main: "#67748e",
    focus: "#67748e",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#141414",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#5e72e4",
    focus: "#5e72e4",
  },

  secondary: {
    main: "#8392ab",
    focus: "#8392ab",
  },

  info: {
    main: "#11cdef",
    focus: "#11cdef",
  },

  success: {
    main: "#2dce89",
    focus: "#2dce89",
  },

  warning: {
    main: "#fb6340",
    focus: "#fb6340",
  },

  error: {
    main: "#f5365c",
    focus: "#f5365c",
  },

  light: {
    main: "#e9ecef",
    focus: "#e9ecef",
  },

  dark: {
    main: "#344767",
    focus: "#344767",
  },

  grey: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#5e72e4",
      state: "#825ee4",
    },

    // 81.62deg, #313860 2.25%, $151928 79.87%
    secondary: {
      main: "#313860",
      state: "#151928",
    },

    info: {
      main: "#1171ef",
      state: "#11cdef",
    },

    success: {
      main: "#2dce89",
      state: "#2dcecc",
    },

    warning: {
      main: "#fb6340",
      state: "#fbb140",
    },

    error: {
      main: "#f5365c",
      state: "#f56036",
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4",
    },

    dark: {
      main: "#323a54",
      state: "#1a2035",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  alertColors: {
    primary: {
      main: "#5e72e4",
      state: "#673bde",
      border: "#cfd5f7",
    },

    secondary: {
      main: "#627594",
      state: "#8ca1cb",
      border: "#dadee6",
    },

    info: {
      main: "#1171ef",
      state: "#0eadca",
      border: "#b8f0fa",
    },

    success: {
      main: "#2dce89",
      state: "#26adab",
      border: "#c0f0dc",
    },

    warning: {
      main: "#fb6340",
      state: "#faa118",
      border: "#fed0c6",
    },

    error: {
      main: "#f5365c",
      state: "#f3410f",
      border: "#fcc3ce",
    },

    light: {
      main: "#ced4da",
      state: "#d1dae6",
      border: "#f8f9fa",
    },

    dark: {
      main: "#212229",
      state: "#0f1112",
      border: "#c2c8d1",
    },
  },

  badgeColors: {
    primary: {
      background: "#eaecfb",
      text: "##2643e9",
    },

    secondary: {
      background: "#e4e8ed",
      text: "#5974a2",
    },

    info: {
      background: "#aaedf9",
      text: "#03acca",
    },

    success: {
      background: "#b0eed3",
      text: "#1aae6f",
    },

    warning: {
      background: "#fee6e0",
      text: "#ff3709",
    },

    error: {
      background: "#fdd1da",
      text: "#f80031",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  // Add from Argon theme
  inputColors: {
    borderColor: { main: "#d2d6da", focus: "#11cdef" },
    error: "#f5365c",
    success: "#2dce89",
  },

  // Add from Argon theme
  sliderColors: {
    thumb: { borderColor: "#d9d9d9" },
  },
  // Add from Argon theme
  circleSliderColors: {
    background: "#d3d3d3",
  },
  // Add from Argon theme?
  tabs: {
    indicator: { boxShadow: "#ddd" },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",
};

export default colors;
