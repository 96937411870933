// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import { Field } from "formik";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "scalpel-components/Users/NewUser/components/FormField";
import selectData from "data/selectData";

function UserInfo({ formData }: any): JSX.Element {
  const { formField, values, errors, touched, setFieldValue, handleBlur } =
    formData;
  const { firstName, lastName, position, email, password, repeatPassword } =
    formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    position: positionV,
    email: emailV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  const handleGetOptionLabel = (option: any) => option.name || "";

  const handleOnChange = (_: any, value: any) =>
    setFieldValue("position", value || {});

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">About User</MDTypography>
        <MDTypography variant="button" color="text">
          User Information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              // defaultValue={""}
              id={`${position.name}-${position.type}`}
              options={selectData.positions}
              getOptionLabel={handleGetOptionLabel}
              // doesn't work properly. The logic is correct, but it activates the class mui-focus on all options
              // isOptionEqualToValue={(option, value) =>
              //   value === undefined ||
              //   value.name === "" ||
              //   // Object.keys(value).length === 0 ||
              //   option.name === value.name
              // }
              value={positionV}
              onChange={handleOnChange}
              onBlur={handleBlur}
              renderOption={(props, option) => (
                <li {...props} key={option.name}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <Field
                  {...params}
                  name={position.name}
                  label={position.label}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  // helperText={errors["name"]}
                  error={errors.position && touched.position}
                  success={positionV.length > 0 && !errors.position}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={repeatPassword.type}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default UserInfo;
