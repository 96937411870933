import {
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useReducer,
  createContext,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Services
import axiosInstance from "context/AxiosInstance";

import { useAlertManager } from "context/AlertManager";

// Import actions for dispatch
// import { login } from "./actions";

// Create UserAuth context
const UserAuth = createContext<any>(null);
// Setting custom name for the context which is visible on react dev tools
UserAuth.displayName = "UserAuth";

// Combine all contexts into one
// https://frontendbyte.com/how-to-use-react-context-api-usereducer-hooks/

interface StateTypes {
  user: any;
  isAuthorized: boolean;
  isLoading: boolean;
  error: any;
}

interface ActionTypes {
  type: "SET_USER" | "IS_AUTHORIZED" | "IS_LOADING" | "ERROR" | "LOGOUT";
  value: any;
}

function reducer(state: StateTypes, action: ActionTypes) {
  switch (action.type) {
    case "SET_USER": {
      return { ...state, user: action.value, isAuthorized: true };
    }
    case "IS_AUTHORIZED": {
      return { ...state, isAuthorized: action.value }; // set user null too? may fix logout issue
    }
    case "IS_LOADING": {
      return { ...state, isLoading: action.value };
    }
    case "ERROR": {
      return { ...state, error: action.value, user: null, isAuthorized: false };
    }
    case "LOGOUT": {
      return { ...state, user: null, isAuthorized: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserAuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const navigate = useNavigate();

  const initialState: StateTypes = {
    user: null,
    isAuthorized: false,
    isLoading: true,
    error: null,
  };

  // Controller stores access to auth context (global state) variables (user, isAuthorized, etc.) in the provider. Dispatch stores "action types": logic to change the state of said auth context variables
  const [authController, authDispatch] = useReducer(reducer, initialState);

  // setUser consumer
  const setUser = async (dispatch: any, value: any) => {
    try {
      dispatch({ type: "SET_USER", value });
    } catch (error) {
      console.error(error);
    }
  };

  // Login consumer
  const login = async (authDispatch: any, value: any) => {
    try {
      // Update isLoading
      authDispatch({ type: "IS_LOADING", value: true });

      // Should return 201 created (fix) with payload of { message: "success"} and attach to browser HttpOnly cookie with jwt token
      await axiosInstance.post("/auth/login", value);

      // JWT auto passed as Bearer Token. /auth/profile endpoint will get user from jwt (bearer token) and return user profile.
      const profile = await axiosInstance.get("/users/profile");
      // Use setUser to authDispatch changes to state
      setUser(authDispatch, profile.data);
    } catch (error) {
      authDispatch({ type: "ERROR", value: error });
      return Promise.reject({
        message: "Error in UserAuth Provider",
      });
    } finally {
      // Update isLoading
      // TRACK ISLOADING TIME AND SET A MINIMUM LOADING TIME FOR EACH LOGIN SO THAT OVERLAY SPINNER WORKS
      setTimeout(
        () => authDispatch({ type: "IS_LOADING", value: false }),
        1500
      );
    }
  };

  // Logout
  const logout = async (authDispatch: any) => {
    try {
      // Update isLoading
      authDispatch({ type: "IS_LOADING", value: true });

      // Sent HTTP request to logout endpoint, which will delete httponly cookie. Should return status 200 and message
      await axiosInstance.get("/auth/logout");
      authDispatch({ type: "LOGOUT" });

      // Set global user state to null
      // setUser(authDispatch, null);
      // Seems to work without setIsAuthorized. Not sure why. TEST: comment line below, logout an already logged in user, after redirected to /login, check authState context. Look to see if 'isAuthorized' stays true even though 'user' was just previously called, which sets users to null BUT sets isAuthorized to true.
      // setIsAuthorized(authDispatch, false);
    } catch (error) {
      authDispatch({ type: "ERROR", value: error });
    } finally {
      setTimeout(() => {
        authDispatch({ type: "IS_LOADING", value: false });
        navigate("/login");
      }, 1500);
    }
  };

  // setIsLoading consumer - proper way to do types
  const setIsLoading = (
    dispatch: (arg: { type: "IS_LOADING"; value: boolean }) => void,
    value: boolean
  ) => dispatch({ type: "IS_LOADING", value });

  const value = useMemo(
    () => [authController, authDispatch, login, logout, setUser, setIsLoading],
    [authController, authDispatch]
  );

  return <UserAuth.Provider value={value}>{children}</UserAuth.Provider>;
}

function useUserAuth() {
  const context = useContext(UserAuth);

  if (!context) {
    throw new Error("useUserAuth should be used inside the UserAuthProvider.");
  }

  return context;
}

UserAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserAuthProvider, useUserAuth };
