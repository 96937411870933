import React, { CSSProperties } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import MDBox from "components/MDBox";
import { useScaleplUIController } from "context/ScalpelUI";

const LoadingOverlay = () => {
  // How to make transition work?
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      bgColor="rgb(7,17,56)"
      position="absolute"
      top="0"
      bottom="0"
      zIndex="100000"
    >
      <PacmanLoader
        color="#fb6340"
        loading={true}
        // loading={loading}
        cssOverride={override}
        size={110}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </MDBox>
  );
};

export default LoadingOverlay;
