import { forwardRef } from "react";

// react-select components
import Select from "react-select";

// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";

// Argon Dashboard 2 PRO MUI context
import { useScaleplUIController } from "context/ScalpelUI";

// Custom styles for MDSelect
import styles from "components/MDSelect/styles";

const MDSelect = forwardRef(
  ({ size, error, success, ...rest }: any, ref: any) => {
    const [controller] = useScaleplUIController();
    const { darkMode } = controller;
    const { light } = colors;

    return (
      <Select
        {...rest}
        ref={ref}
        styles={styles(size, error, success, darkMode)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
    );
  }
);

export default MDSelect;
