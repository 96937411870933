import { useState, useContext, useEffect, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "context/UserAuth";
import PacmanLoader from "react-spinners/PacmanLoader";
import MDBox from "components/MDBox";
import LoadingOverlay from "scalpel-components/LoadingOverlay";
import Delayed from "scalpel-components/Delayed";

function RequireAuth({ children }: any) {
  // Use auth context to get isAuthorized and isLoading
  const [authController, authDispatch] = useUserAuth();
  const { user, isAuthorized, isLoading } = authController;

  // Location
  const location = useLocation();

  // Spinner overlay
  let [loading, setLoading] = useState(true);
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return isAuthorized ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export default RequireAuth;
