import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 PRO MUI components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Navbar from "scalpel-components/Navbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";
import DataTable from "examples/Tables/DataTable";

// Reports page components
import Reviews from "scalpel-components/Users/Overview/components/Reviews";

// Data
import { useUsersController } from "context/Users";
import tableData from "scalpel-components/Users/Overview/data/tableData";
import dataTableData from "scalpel-components/Users/Overview/data/dataTableData";
import columnsData from "./data/columnsData";

function Reports(): JSX.Element {
  const [usersController] = useUsersController();
  const { users } = usersController;
  console.log(users);
  // const { columns, rows } = tableData;
  const [columns, setColumns] = useState([]);

  useEffect(() => {}, []);

  return (
    <DashboardLayout>
      <Navbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="account_circle"
                    title="Users Actice"
                    count={1600}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "than than a week",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="account_circle"
                    title="Users Actice"
                    count={1600}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "than than a week",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="account_circle"
                    title="Users Actice"
                    count={1600}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "than than a week",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="account_circle"
                    title="Users Actice"
                    count={1600}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "than than a week",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={3}
              >
                <MDBox lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    All Users
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    All Scalpel users across Merkle.
                  </MDTypography>
                </MDBox>
                <Stack spacing={1} direction="row">
                  <Link to="/users/new-user">
                    <MDButton variant="gradient" color="info" size="small">
                      + New User
                    </MDButton>
                  </Link>
                </Stack>
              </MDBox>
              <DataTable
                table={{ columns: columnsData, rows: users }}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [10, 25, 50, 100, 250],
                }}
                canSearch
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
