/* eslint-disable no-unused-vars */

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import {
  useState,
  useRef,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";

import ScalpelSnackbar from "scalpel-components/ScalpelUISnackbar";

// AlertManager Context
const AlertManager = createContext<any>(null);

// Setting custom name for the context which is visible on react dev tools
AlertManager.displayName = "AlertsContext";

// AlertManager context provider
function AlertManagerProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState(undefined);
  const queueRef = useRef([]);

  function handleClose(event: any, reason: any) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  function processQueue() {
    if (queueRef.current.length > 0) {
      setMessageData(queueRef.current.shift());
      setOpen(true);
    }
  }

  function handleExited() {
    processQueue();
  }

  function createNotification(notification: any) {
    queueRef.current.push(notification);

    if (open) {
      // If it is already open, close it, calls handleExited.
      setOpen(false);
    } else {
      // If not opened already, process your notification.
      processQueue();
    }
  }

  return (
    <AlertManager.Provider value={{ createNotification }}>
      {children}
      <ScalpelSnackbar
        {...messageData} // color, title, content
        open={open}
        onClose={handleClose}
        close={handleExited}
        icon="check"
        dateTime="1 min(s) ago"
      />
    </AlertManager.Provider>
  );
}

// AlertManager custom hook for using context
function useAlertManager() {
  const context = useContext(AlertManager);

  if (!context) {
    throw new Error(
      "useAlertManager should be used inside the AlertManagerControllerProvider."
    );
  }

  return context;
}

export { AlertManagerProvider, useAlertManager };
