/* eslint-disable no-unused-vars */

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useState,
} from "react";

import axiosInstance from "context/AxiosInstance";

// Users Context
const Users = createContext<any>(null);

// Setting custom name for the context which is visible on react dev tools
Users.displayName = "UsersContext";

interface Region {
  code: string;
  name: string;
}

interface UserType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  position: string;
  region: Region;
  languages: string[];
  roles: string[];
}

// types
interface StateTypes {
  users: [];
}

interface ActionTypes {
  type: "SET_USERS" | "ADD_USER" | "MODIFY_USER" | "DELETE_USER";
  value: any;
}

// Scalpel UI reducer
function reducer(state: StateTypes, action: ActionTypes) {
  switch (action.type) {
    case "SET_USERS": {
      return { ...state, users: action.value };
    }
    case "ADD_USER": {
      return { ...state, users: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function UsersControllerProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const initialState: StateTypes = {
    users: [],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  // Get and set users when provider component renders
  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await axiosInstance.get("/users");
        setUsers(dispatch, users.data);
      } catch (error) {
        console.error(error);
      } finally {
        // Close overlay
      }
    };

    getUsers();
  }, []);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <Users.Provider value={value}>{children}</Users.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useUsersController() {
  const context = useContext(Users);

  if (!context) {
    throw new Error(
      "useUsersController should be used inside the UsersControllerProvider."
    );
  }

  return context;
}

// Context module functions
const setUsers = (
  dispatch: (arg: { type: "SET_USERS"; value: boolean }) => void,
  value: boolean
) => dispatch({ type: "SET_USERS", value });

export { UsersControllerProvider, useUsersController, setUsers };
