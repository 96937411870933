import { useState, useMemo } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";

function ProfilePic({ formData }: any): JSX.Element {
  const { setFieldValue } = formData;

  const handleProfilePicChange = (value: any) => {
    console.log(value);
    setFieldValue("profilePic", value || {});
  };

  return (
    <MDBox>
      <MDTypography variant="h5">ProfilePic</MDTypography>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography
            component="label"
            variant="button"
            fontWeight="regular"
            color="text"
          >
            Profile Pic
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                addRemoveLinks: true,
                withCredentials: true,
                url: `${process.env.REACT_APP_BASE_API_URL}/users/avatar`,
              }}
              handleProfilePicChange={handleProfilePicChange}
            />
          ),
          []
        )}
      </MDBox>
    </MDBox>
  );
}

export default ProfilePic;
