import axios, { AxiosError } from "axios";
import { useState, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
// import { useAlertManagerController, openAlert } from "context/AlertManager";

// import { useUserAuth } from "context/UserAuth";

// axios instance
const axiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
    // Accept: "application/json", ?
  },
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: true,
  //   Access-Control?
});

const AxiosContextProvider = ({ children }: { children: any }): JSX.Element => {
  const [isSet, setIsSet] = useState(false);
  // Auth Context for App wide user and authorization state
  // const [, authDispatch, , logout] = useUserAuth();
  // const [alertController, alertDispatch] = useAlertManagerController();

  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = (error: any) => {
      const isAxiosError = error instanceof AxiosError ? true : false;
      const statusCode = error.response ? error.response.status : null;

      switch (statusCode) {
        case 400: {
          return Promise.reject({
            message: `Error ${statusCode}`,
            headers: error.response.headers,
            data: error.response.data,
          });
        }
        case 401: {
          //redirect logic here

          // logout(authDispatch);
          // navigate("/login");

          return Promise.reject({
            message: `Error ${statusCode}`,
            headers: error.response.headers,
            data: error.response.data,
          });
        }
        case 404: {
          return Promise.reject({
            message: "Error in AxiosContext. Error: 404",
          });
        }
        default: {
          throw new Error(
            `AxiosContext: Unhandled error status code: ${statusCode}`
          );
        }
      }
    };

    const interceptor = axiosInstance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    setIsSet(true);

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export default axiosInstance;
export { AxiosContextProvider };
