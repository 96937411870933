import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useUserAuth } from "context/UserAuth";

import { useAlertManager } from "context/AlertManager";

// import { useAlertManagerController, openAlert } from "context/AlertManager";

function Login(): JSX.Element {
  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [, userAuthDispatch, login, setUser] = useUserAuth();
  const { createNotification } = useAlertManager();

  // Location and navigate - fix state type
  const { state }: any = useLocation();
  const navigate = useNavigate();

  // States for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Lookup how to type the event object https://stackoverflow.com/questions/67143951/typescript-and-react-what-is-the-proper-type-for-an-event-handler-that-uses-des
  // Handlers
  const handleSetEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSetPassword = (event: any) => {
    setPassword(event.target.value);
  };

  // THEN GO TO APP AND WRAP SIDEBAR ET AL. AND CHECK ISLOADING TO STOP FLASH

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      // Login request
      let userPayload = { email, password };
      const response = await login(userAuthDispatch, userPayload);
      // Navigate to location path or /dashboard. Not sure if working properly as /logout should cause loop. Update: apparently this issue is solved by going to UserAuth -> logout() and changing the order of the redirect and dispatch call. Not sure if true
      const currentPath = state?.path !== "/logout" ? state?.path : null;
      console.log(currentPath);
      navigate(currentPath || "/dashboard");
    } catch (error) {
      createNotification({
        color: "error",
        title: "Error: Unauthorized",
        content: "Email or password incorrect",
      });
    }
  };

  const onSubmitHandler = async (event: any) => {
    console.log(event);
  };

  const onKeyPressHandler = (event: any) => {
    console.log(event);
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={handleSetEmail}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                onChange={handleSetPassword}
                fullWidth
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                onKeyPress={onKeyPressHandler}
                fullWidth
              >
                Login
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                For Merkle employees only
                {/* <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography> */}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
