/**
=========================================================

* SEO Scalpel
* Merkle Inc
* Developer: Ryan Kennedy
* Theme: Material Dashboard 2 PRO React TS - v1.0.1

=========================================================

* Product Page: https://seoscalpel.com
* Copyright 2023 Merkle Inc

=========================================================
*/

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React TS Context Provider
import { ScaleplUIControllerProvider } from "context/ScalpelUI";
import { UserAuthProvider } from "context/UserAuth";
import { AxiosContextProvider } from "context/AxiosInstance";
import { UsersControllerProvider } from "context/Users";

ReactDOM.render(
  <BrowserRouter>
    <ScaleplUIControllerProvider>
      <AxiosContextProvider>
        <UserAuthProvider>
          <UsersControllerProvider>
            <App />
          </UsersControllerProvider>
        </UserAuthProvider>
      </AxiosContextProvider>
    </ScaleplUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
