import { useEffect } from "react";
import { useUserAuth } from "context/UserAuth";
import { useNavigate } from "react-router-dom";

const Logout = (): JSX.Element => {
  const [, authDispatch, , logout] = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout(authDispatch);
  }, []);

  return (
    <>
      <div>ukjhjk</div>
    </>
  );
};

export default Logout;
