// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import { Field } from "formik";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "scalpel-components/Users/NewUser/components/FormField";
import FormSelectField from "scalpel-components/Users/NewUser/components/FormSelectField";

// Data
import { useUsersController } from "context/Users";
import selectData from "data/selectData";

// region code/name, languages, roles, profile picture, team leader, team members

function AdditionalInfo({ formData }: any): JSX.Element {
  const { formField, values, errors, touched, setFieldValue, handleBlur } =
    formData;
  const {
    teamLeader,
    roles,
    region,
    languages,
    address1,
    address2,
    city,
    zip,
    position,
  } = formField;
  const {
    address1: address1V,
    address2: address2V,
    city: cityV,
    zip: zipV,
    region: regionV,
    languages: languagesV,
    roles: rolesV,
    teamLeader: teamLeaderV,
  } = values;

  const [usersController] = useUsersController();
  const { users } = usersController;

  const handleGetOptionLabel = (option: any) => option.name || "";

  const handleGetLeaderLabel = (option: any) =>
    `${option.firstName} ${option.lastName}` || "";

  const getNamesOnly = (value: any) =>
    value.map((item: any) => item.name.toLowerCase());

  const handleRegionChange = (_: any, value: any) =>
    setFieldValue("region", value || {});

  const handleLanguagesChange = (_: any, value: any) => {
    const allLanguages = value.map((role: any) => role.toLowerCase());
    setFieldValue("languages", allLanguages || {});
  };

  const handleRolesChange = (_: any, value: any) => {
    const allRoles = value.map((role: any) => role.toLowerCase());
    setFieldValue("roles", allRoles || {});
  };

  const handleTeamLeaderChange = (_: any, value: any) => {
    console.log(value);
    setFieldValue("teamLeader", value.id || {});
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Additional Info</MDTypography>
        <MDTypography variant="button" color="text">
          Additional User Information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.625}>
              <Autocomplete
                // defaultValue={""}
                id={`${region.name}-${region.type}`}
                options={selectData.countries}
                getOptionLabel={handleGetOptionLabel}
                value={regionV}
                onChange={handleRegionChange}
                onBlur={handleBlur}
                renderOption={(props, option) => (
                  <li {...props} key={option.name}>
                    {option.emoji} {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <Field
                    {...params}
                    name={region.name}
                    label={region.label}
                    as={MDInput}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              // value={languagesV}
              // defaultValue={[{ name: "English", label: "English" }]}
              options={selectData.languages}
              onChange={handleLanguagesChange}
              renderInput={(params) => (
                <Field
                  {...params}
                  name={languages.name}
                  label={languages.label}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              // defaultValue={["User"]}
              options={selectData.roles}
              onChange={handleRolesChange}
              renderInput={(params) => (
                <Field
                  {...params}
                  name={roles.name}
                  label={roles.label}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              // defaultValue={""}
              id={`${teamLeader.name}-${teamLeader.type}`}
              options={users}
              getOptionLabel={handleGetLeaderLabel}
              onChange={handleTeamLeaderChange}
              onBlur={handleBlur}
              // renderOption={(props, option) => (
              //   <li {...props} key={option.firstName}>
              //     {option.firstName} {option.lastName}
              //   </li>
              // )}
              renderInput={(params) => (
                <Field
                  {...params}
                  name={teamLeader.name}
                  label={teamLeader.label}
                  as={MDInput}
                  variant="standard"
                  fullWidth
                  value={teamLeaderV}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AdditionalInfo;
